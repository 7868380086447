<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-0">
        a) What classifications are the fire extinguishers in the Chemistry 1120 laboratory?
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="pl-6 mt-1">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value">
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </div>
      </v-radio-group>

      <p class="mb-0">b) For what types of fire can this type of extinguisher be used?</p>

      <v-radio-group v-model="inputs.input2" :disabled="!allowEditing" class="pl-6 mt-1">
        <div v-for="option in options2" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value">
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UPEISafety1',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
      options1: [
        {text: 'Carbon dioxide', value: 'co2'},
        {text: 'Water mist', value: 'h2o'},
        {text: 'ABC powder', value: 'abc'},
        {text: 'Wet Chemical', value: 'wetChem'},
      ],
      options2: [
        {text: 'Paper', value: 'paper'},
        {text: 'Trash', value: 'trash'},
        {text: 'Wood', value: 'wood'},
        {text: 'Electrical', value: 'elec'},
        {text: 'Liquid', value: 'liquid'},
        {text: 'All of the above', value: 'all'},
      ],
    };
  },
};
</script>
